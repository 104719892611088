import CountryOutline from './CountryOutline/CountryOutline'
import CardIcon from './CardIcon/CardIcon'
import NotAllowedIcon from './NotAllowed/NotAllowed'
import XIcon from './X/X'
import CheckIcon from './Check/Check'
import AvatarIcon from './Avatar/Avatar'
import MatchesIcon from './Matches/Matches'
import CasesIcon from './Cases/Cases'
import TakedownIcon from './Takedown/Takedown'
import MultiImagesIcon from './MultiImages/MultiImages'
import MatchesPlusIcon from './MatchesPlus/MatchesPlus'
import SettingsIcon from './Settings/Settings'

export * from './Arrows'

export {
  CountryOutline,
  CardIcon,
  NotAllowedIcon,
  XIcon,
  CheckIcon,
  AvatarIcon,
  MatchesIcon,
  CasesIcon,
  TakedownIcon,
  MultiImagesIcon,
  MatchesPlusIcon,
  SettingsIcon,
}
