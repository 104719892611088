import TickCheckbox from './TickCheckbox'
import TickCheckboxLeft from './TickCheckboxLeft'
import RadioBar from './RadioBar'
import ClosedQuestionRadio from './ClosedQuestionRadio'
import AdditionalLinks from './AdditionalLinks'
import TextField from './TextField'
import TextFieldVertical from './TextFieldVertical'
import TextFieldPlain from './TextFieldPlain'
import TextFieldNoLabel from './TextFieldNoLabel'
import TextArea from './TextArea'
import TextAreaVertical from './TextAreaVertical'
import MultipleEmailField from './MultipleEmailField'
import WideFileUpload from './WideFileUpload'
import WideFileUploadLegacy from './WideFileUploadLegacy'
import TitleSelect from './TitleSelect'
import MatchReportNotificationSelect from './MatchReportNotificationSelect'
import JurisdictionSelect from './JurisdictionSelect'
import DatePicker from './DatePicker'
import DatePickerVertical from './DatePickerVertical'
import PhantomLabelSpacer from './PhantomLabelSpacer'
import StaticText from './StaticText'
import PathSelectionSlider from './PathSelectionSlider'
import VerticalRadioGroup from './VerticalRadioGroup'
import VerticalCheckboxGroup from './VerticalCheckboxGroup'
import StripeCardInput from './StripeCardInput'
import StripeCardInputInline from './StripeCardInputInline'
import CompoundFields from './CompoundFields'
import CompoundFieldsDoublePrice from './CompoundFieldsDoublePrice'
// import CompoundFieldsSinglePrice from './CompoundFieldsSinglePrice';
import PriceValueInputStandalone from './PriceValueInputStandalone'
import CreativeCommonsSelect from './CreativeCommonsSelect'
import TypeOfWorkSelect from './TypeOfWorkSelect'
import ClipboardHelper from './ClipboardHelper'
import FormRow from './FormRow'
import TwoInputsNextToEachOther from './TwoInputsNextToEachOther'
import CountrySelect from './CountrySelect'
import USCOSelect from './USCOSelect'
import AlignLabels from './AlignLabels'
import Label from './Label'
import Feedback from './Feedback'
import RegistrationTypeBar from './RegistrationTypeBar'
import RegistrationTypeSelect from './RegistrationTypeSelect'
// redesigned input components with uppercase labels similar to labels on the match view:
import ClosedQuestionRadio_ from './ClosedQuestionRadio_'
import DatePickerVertical_ from './DatePickerVertical_'
import Label_ from './Label_'
import LockedValue from './LockedValue'
import TextField_ from './TextField_'
import TextFieldVertical_ from './TextFieldVertical_'

export {
  TickCheckbox,
  TickCheckboxLeft,
  RadioBar,
  ClosedQuestionRadio,
  AdditionalLinks,
  TextField,
  TextFieldVertical,
  TextFieldPlain,
  TextFieldNoLabel,
  TextArea,
  TextAreaVertical,
  MultipleEmailField,
  WideFileUpload,
  WideFileUploadLegacy,
  TitleSelect,
  MatchReportNotificationSelect,
  JurisdictionSelect,
  DatePicker,
  DatePickerVertical,
  PhantomLabelSpacer,
  StaticText,
  PathSelectionSlider,
  VerticalRadioGroup,
  VerticalCheckboxGroup,
  StripeCardInput,
  StripeCardInputInline,
  CompoundFields,
  CompoundFieldsDoublePrice,
  // CompoundFieldsSinglePrice,
  PriceValueInputStandalone,
  CreativeCommonsSelect,
  TypeOfWorkSelect,
  ClipboardHelper,
  FormRow,
  TwoInputsNextToEachOther,
  CountrySelect,
  USCOSelect,
  AlignLabels,
  Label,
  Feedback,
  RegistrationTypeBar,
  RegistrationTypeSelect,
  // redesigned input components with uppercase labels similar to labels on the match view:
  ClosedQuestionRadio_,
  DatePickerVertical_,
  Label_,
  LockedValue,
  TextField_,
  TextFieldVertical_,
}

export { DatePickerStandalone } from './DatePicker'
export { CurrencySelectStandalone } from './CurrencySelect'
export { CountrySelectStandalone } from './CountrySelect'
export { USCOSelectStandalone } from './USCOSelect'
