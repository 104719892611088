import * as React from 'react'
import { css } from 'react-emotion'

export const PixsyBreadcrumbsText = ({ children }) => (
  <p css={pixsyBreadcrumbsText}>{children}</p>
)

const pixsyBreadcrumbsText = css`
  margin: 0;
  padding: 0;
  display: inline-block;
`
