import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { css } from 'react-emotion'
import { Elements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js'
import { notifSend } from 'redux-notifications/lib/actions'
import {
  StripeCardInputInline,
  AlignLabels,
  TextFieldNoLabel,
  CountrySelectStandalone,
  Button,
  SexySeparator,
} from 'common'
import COUNTRIES from 'pixsy-constants/countries.json'
import { handleStripePayment } from '../../../../../redux/modules/registrations'

class StripeForm extends Component {
  constructor(props) {
    super(props)
    const { user } = props
    this.state = {
      name: user.details.legal_name,
      street: user.details.street,
      postalCode: user.details.postal_code,
      state: user.details.state,
      city: user.details.city,
      country: user.details.country,
      email: user.email,
    }
  }

  handleChange = (key) => (e) => {
    e.preventDefault()
    this.setState({ [key]: e.target.value })
  }

  handleSubmit = async (values) => {
    const { registration, notifSend, dispatch, stripe, elements } = this.props
    const country = COUNTRIES.find((country) => country.country === this.state.country)

    if (!stripe || !elements) {
      return notifSend({
        message:
          'Our payment partner Stripe is unable to verify the authenticity of your payment. Please disable any content blocking features and/or AdBlockers on this page, reload, and try again.',
        kind: 'warning',
        dismissAfter: 15e3,
      })
    }

    const cardElement = elements.getElement(CardElement)

    const response = await stripe.createToken(cardElement, {
      name: this.state.name,
      address_line1: this.state.street,
      address_line2: this.state.postalCode,
      address_city: this.state.city,
      address_state: this.state.state,
      address_country: country ? country.iso : null,
    })

    if (response.error) {
      notifSend({
        message: response.error.message,
        kind: 'warning',
        dismissAfter: 6000,
      })
    } else {
      const coupon =
        (typeof registration.couponUsed === 'string' &&
          registration.couponUsed.length > 0 &&
          registration.couponUsed) ||
        undefined
      return dispatch(
        handleStripePayment(registration._id, {
          token: response.token.id,
          registrationId: registration.registration__c,
          email: this.state.email,
          coupon,
        })
      )
    }
  }

  render() {
    const { handleSubmit, submitting } = this.props
    return (
      <form css={wrap} onSubmit={handleSubmit(this.handleSubmit)}>
        <AlignLabels align="right" width="190px">
          <Field
            name="name"
            label="Name on Card"
            input={{
              value: this.state.name,
              onChange: this.handleChange('name'),
            }}
            component={TextFieldNoLabel}
          />
          <Field
            name="street"
            label="Street"
            input={{
              value: this.state.street,
              onChange: this.handleChange('street'),
            }}
            component={TextFieldNoLabel}
          />
          <div css={half}>
            <div>
              <Field
                name="postal_code"
                label="Postal Code"
                input={{
                  value: this.state.postalCode,
                  onChange: this.handleChange('postalCode'),
                }}
                component={TextFieldNoLabel}
              />
            </div>
            <div>
              <Field
                name="city"
                label="City"
                input={{
                  value: this.state.city,
                  onChange: this.handleChange('city'),
                }}
                component={TextFieldNoLabel}
              />
            </div>
          </div>
          <div css={half}>
            <div>
              <Field
                name="state"
                label="State"
                input={{
                  value: this.state.state,
                  onChange: this.handleChange('state'),
                }}
                component={TextFieldNoLabel}
              />
            </div>
            <div>
              <Field
                name="country"
                label="Country"
                input={{
                  value: this.state.country,
                  onChange: this.handleChange('country'),
                }}
                component={CountrySelectStandalone}
                embedded
              />
            </div>
          </div>
          <SexySeparator space={20} />

          <Field type="text" label="Card Number" name="card" component={StripeCardInputInline} />

          <Field
            name="email"
            label="Email for Receipt"
            input={{
              value: this.state.email,
              onChange: this.handleChange('email'),
            }}
            component={TextFieldNoLabel}
          />

          {/* <Field
            name="coupon"
            label="Coupon"
            maxLength={50}
            component={TextFieldPlain}
          /> */}

          <div css={buttonAndStripe}>
            <Button submit disabled={submitting}>
              Secure Payment
            </Button>
            <a href="https://stripe.com" target="_blank">
              <div css={stripeWrap}>
                <img src="/img/powered_by_stripe.svg" />
              </div>
            </a>
          </div>
        </AlignLabels>
      </form>
    )
  }
}

const InjectedStripeForm = (props) => (
  <ElementsConsumer>
    {({ stripe, elements }) => <StripeForm stripe={stripe} elements={elements} {...props} />}
  </ElementsConsumer>
)

const ConnectedStripePaymentForm = reduxForm({
  form: 'registrationPaymentForm',
  onSubmit: (values, dispatch, props) =>
    dispatch(handleStripePayment(values)).then(() => props.onSuccess && props.onSuccess()),
})(connect(null, { notifSend })(InjectedStripeForm))

export default function StripePaymentForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <Elements stripe={stripe} elements={elements}>
          <ConnectedStripePaymentForm {...props} />
        </Elements>
      )}
    </ElementsConsumer>
  )
}

const wrap = css`
  position: relative;
  & > div[class*='-Wrap'],
  & > div[class*='-half'] {
    margin-top: 25px;
  }
`

const buttonAndStripe = css`
  display: flex;
  justify-content: space-between;
  margin: 30px 0 20px;
`

const stripeWrap = css`
  margin-top: 10px;
`

const half = css`
  display: flex;
  justify-content: space-between;
  > * {
    flex-basis: 48% !important;
  }
  flex: 1;
`
