import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { css, cx } from 'react-emotion'
import { CheckboxSwitcher, PayPalButton, SexySeparator } from 'common'
import { Link } from 'react-router-dom'
import StripePaymentForm from './StripePaymentForm'
import InstalmentPaymentSection from './InstalmentPaymentSection'
import moment from 'moment'
import { STRIPE_ZERO_DECIMAL_CURRENCIES } from 'pixsy-constants'

export default class PaymentsContainer extends Component {
  constructor(props) {
    super(props)

    this.setStripe = () => this.setState({ method: 'stripe' })
    this.setPaypal = () => this.setState({ method: 'paypal' })

    const startDate = moment()
    this.maxDate = moment.utc(startDate).add(1, 'weeks')
    this.minDate = startDate
    this.totalPrice = props.case.salesforce.FinalPrice__c || props.case.salesforce.CurrentPrice__c

    const parts = 2
    this.state = {
      partPayments: false,
      parts,
      interval: 'weekly',
      method: 'stripe',
      startDate,
      currentPrice: this.getInstalmentPrice(parts),
    }
  }

  getInstalmentPrice(parts) {
    // convert to cents and back to make it easier to work with
    // the remainder (same as it is done in our API)
    const currency = this.props.case.salesforce.CurrencyIsoCode
    const multiplier = STRIPE_ZERO_DECIMAL_CURRENCIES.includes(currency) ? 1 : 100
    const price = this.totalPrice * multiplier

    let currentPrice = price
    while (currentPrice % parts !== 0) {
      currentPrice = Math.floor(Number(currentPrice + 1))
    }

    return Number(currentPrice / parts / multiplier).toFixed(2)
  }

  onPartPaymentsChange(e) {
    const currentState = this.state.partPayments
    this.setState({ partPayments: !currentState })
  }

  handleInstalmentChange(config) {
    if (config.startDate) {
      if (config.startDate.isAfter(this.maxDate) || this.minDate.isAfter(config.startDate)) {
        return
      }
    }
    if (config.parts) {
      const currentPrice = this.getInstalmentPrice(config.parts)
      this.setState({ ...config, currentPrice })
    } else {
      this.setState({ ...config })
    }
  }

  render() {
    const { case: caze, onPaymentSuccess } = this.props
    const { method, partPayments, parts, interval, startDate, currentPrice } = this.state

    const currency = caze.salesforce.CurrencyIsoCode

    const paymentFormProps = {
      caze,
      onPaymentSuccess,
    }

    return (
      <div css={wrap}>
        <div css={header}>
          <h5>Payment Method</h5>
          {method === 'stripe' && (
            <CheckboxSwitcher
              onLabel="Part Payments On"
              offLabel="Off"
              onChange={(e) => this.onPartPaymentsChange(e)}
              checked={partPayments}
            />
          )}
        </div>
        <div css={methodSelection}>
          <button className={cx({ active: method === 'stripe' })} onClick={this.setStripe}>
            Credit Card
          </button>
          <button className={cx({ active: method === 'paypal' })} onClick={this.setPaypal}>
            Paypal
          </button>
        </div>
        <div css={paymentFormWrap}>
          {partPayments && method === 'stripe' && (
            <InstalmentPaymentSection
              startDate={startDate}
              minDate={this.minDate}
              maxDate={this.maxDate}
              onChange={(config) => this.handleInstalmentChange(config)}
              parts={parts}
              interval={interval}
              amount={currentPrice}
              currency={currency}
            />
          )}
          <SexySeparator />
          {method === 'stripe' ? (
            <StripePaymentForm
              {...paymentFormProps}
              instalment={partPayments}
              parts={parts}
              interval={interval}
              startDate={startDate}
              onSuccess={onPaymentSuccess}
            />
          ) : (
            <PayPalButton type="cases" requestId={caze.salesforce.Id} onSuccess={onPaymentSuccess} />
          )}
        </div>

        <p css={greyNote}>
          By submitting payment you agree to the{' '}
          <Link to="/agreements/licensing-agreement">Image Licensing Agreement</Link>
        </p>
      </div>
    )
  }
}

PaymentsContainer.propTypes = {
  case: PropTypes.object.isRequired,
  onPaymentSuccess: PropTypes.func,
}

const wrap = css`
  max-width: 600px;

  h5 {
    color: #434343ff;
    font-size: 0.9em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.15em;
  }
`

const header = css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h5 {
    opacity: 0.8;
  }

  h5.active {
    opacity: 1;
  }
`

const paymentFormWrap = css`
  margin: 30px auto 10px;
`

const methodSelection = css`
  display: flex;

  > button {
    appearance: none;
    background-color: #ffffff;
    border: 0;
    height: 55px;
    width: 100%;
    flex-grow: 1;
    font-size: 1.2em;
    line-height: 15px;
    font-weight: 400;
    outline: 1px solid #e8e8e8;
    outline-offset: -1px;
    padding: 10px;
    opacity: 0.7;

    &:hover:not(.active) {
      opacity: 1;
      box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
    }

    &:active {
      outline: inherit;
    }

    &.active {
      opacity: 1;
      color: #008aab;

      outline: 2px solid #008aab;
      outline-offset: -2px;
    }
  }
`

const greyNote = css`
  color: #3b3b3baa;
`
