import React, { useState } from 'react'
import { css } from 'react-emotion'
import { useMutation } from '@apollo/client'
import addSftpCredentials from '../../../../graphql/queries/addSftpCredentials.mutation'
import { Button } from 'common'
import uuidV4 from 'uuid/v4'

export function FTPUploadDialog(props) {
  const userLogin = props.user._id
  const [userPassword, setUserPassword] = useState('xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx')
  const [requestStatus, setRequestStatus] = useState('idle')
  const [passwordTimeStamp, setPasswordTimeStamp] = useState(new Date(0))
  const [confirmation, setConfirmation] = useState(false)

  const [addSftpCredentialsMutation] = useMutation(addSftpCredentials)

  const performCredentialsGeneration = async () => {
    const newUserPassword = uuidV4()
    setUserPassword(newUserPassword)
    setRequestStatus('submitting')

    // Since "add" function can update already existing
    // credentials as well - we don't need to delete
    // them beforehand
    addSftpCredentialsMutation({
      variables: { password: newUserPassword },
    })
      .then((addResult) => {
        if (addResult.data.addSftpUser.ftp) {
          setPasswordTimeStamp(new Date(addResult.data.addSftpUser.ftp))
          setRequestStatus('success')
        } else {
          throw new Error()
        }
      })
      .catch(() => {
        setRequestStatus('error')
      })
      .then(() => {
        setConfirmation(false)
      })
  }

  return (
    <div css={content}>
      <h1>sFTP Upload</h1>
      <p className="subheading">Upload images to your Pixsy user account using FTP</p>
      <table>
        <tr>
          <td>Protocol</td>
          <td>
            <b>sFTP</b>
          </td>
        </tr>
        <tr>
          <td>Server</td>
          <td>
            <b>ftp.pixsy.io</b>
          </td>
        </tr>
        <tr>
          <td>Port</td>
          <td>
            <b>22</b>
          </td>
        </tr>
      </table>
      <i>Uploaded images may take up to 15 minutes to appear in your Pixsy account.</i>
      <label>
        <span>Login</span>
        <input type="text" value={userLogin} disabled />
      </label>
      <label>
        <span>Password</span>
        <input type={requestStatus !== 'success' ? 'password' : 'text'} disabled value={userPassword} />
      </label>
      {confirmation ? (
        <React.Fragment>
          <h1>Are you sure?</h1>
          <p className="confirmation">This will invalidate your current credentials.</p>
          <div />
          <Button onClick={performCredentialsGeneration} disabled={requestStatus === 'submitting'}>
            {requestStatus === 'submitting' ? 'Generating...' : 'Yes, Generate New Password'}
          </Button>
        </React.Fragment>
      ) : (
        <Button onClick={() => setConfirmation(true)} disabled={confirmation} domProps={{ style: { width: '100%' } }}>
          Generate New Password
        </Button>
      )}
      {requestStatus === 'error' ? (
        <p className="error">
          There was an error generating credentials for you. Please, try again or contact <br />
          <a href="mailto:sales@pixsy.com">sales@pixsy.com</a>
        </p>
      ) : (
        <div />
      )}
      {requestStatus === 'success' ? (
        <p>
          FTP credentials generated at {passwordTimeStamp.toLocaleString()}.{' '}
          <b>Please save this password somewhere safe as you will not be able to access it again.</b>
        </p>
      ) : (
        <div />
      )}
    </div>
  )
}

export default FTPUploadDialog

const content = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 10px 10px 10px;

  h1 {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  table {
    width: 200px;
    font-size: 1.1em;

    td {
      padding: 8px 0;
      font-weight: 500;
    }

    b {
      background: rgba(0, 0, 0, 0.1);
      padding: 0.25em;
    }
  }

  i {
    margin-top: 5px;
    margin-bottom: 20px;
    font-size: 1.1em;
    color: rgba(0, 0, 0, 0.3);
  }

  label {
    width: 100%;
    margin-bottom: 30px;

    span {
      color: rgba(0, 0, 0, 0.7);
      font-size: 1.2em;
      font-weight: 500;
    }
  }

  input[type='text'],
  input[type='password'] {
    width: 100%;
    padding: 0.4em;
    font-size: 1.2em;
    font-weight: 500;
    border: 1px solid rgb(153, 153, 153);
  }

  p {
    text-align: center;
    margin: 12px 0 0 0;

    &.subheading {
      margin-top: 0;
      margin-bottom: 20px;
      font-size: 1.5em;
      font-weight: 300;
    }

    &.error {
      background-color: #fff0f0;
    }

    &.confirmation {
      color: red;
      padding-bottom: 12px;
      font-size: 1.2em;
    }
  }
`
