import React, { Component } from 'react'
import Modal from 'react-modal'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

export default class UppyModal extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    // Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
    Modal.setAppElement('#react-root')
  }

  handleClick = e => {
    e.stopPropagation()
  }

  render() {
    return (
      <Modal
        onClick={this.handleClick}
        isOpen={this.props.modalOpen}
        style={customStyles}
        contentLabel="Uppy Modal"
      />
    )
  }
}
