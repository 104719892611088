import React from 'react'
import { css } from 'react-emotion'
import { ContentSection, SexySeparator, SexyDefinitionList, SexyThumbnail, ChooseBestImageSource } from 'common'
import moment from 'moment'

const wrap = css`
  h3 {
    font-weight: 400;
    margin-top: 0;
    font-size: 1.7em;
    margin: 0 auto 30px;
  }
`

const flex = css`
  display: flex;
`

const leftCol = css`
  width: 280px;
  margin-right: 40px;
`

const rightCol = css`
  flex: 1;
`

const danger = css`
  color: #e66363;
`

const statusWrap = css`
  text-align: center;
  position: absolute;
  right: 35px;
  top: 20px;
  @media screen and (max-width: 1020px) {
    position: relative;
    top: 0;
    right: 0;
  }
  > dt {
    color: #43434380;
    font-size: 0.9em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.15em;
  }
  > dd {
    font-size: 32px;
    line-height: 32px;
    font-weight: bolder;
  }
`

const Status = ({ paid, instalment }) => {
  let text = 'Unpaid'
  if (paid) {
    text = 'Paid'
  }
  if (instalment && !paid) {
    text = 'Processing'
  }

  return (
    <dl css={statusWrap}>
      <dt>Status</dt>
      <dd>{text}</dd>
    </dl>
  )
}

export default function HighlightsSection({ case: caze, uses: uses, inLegal, paid, instalment }) {
  return (
    <ContentSection>
      <div className={wrap}>
        <h1>Case Reference {caze.salesforce.Name}</h1>
        {!inLegal && <Status paid={paid} type={caze.salesforce.PaymentType__c} instalment={instalment} />}
        <br />
        <div css={flex}>
          <div css={leftCol}>
            <h3>Summary</h3>
          </div>
          <div css={rightCol}>
            <SexyDefinitionList
              items={[
                ['Pixsy Client', caze.salesforce.RightsHolder_Name_FirstLast__c],
                [
                  'Image User',
                  // caze.salesforce.InfringerAccount__r.Name &&
                  //   caze.salesforce.InfringerAccount__r.Name.replace(
                  //     /incomplete/gi,
                  //     ''
                  //   ),
                ],
              ]}
            />
          </div>
        </div>
        <SexySeparator space={20} />
        {uses.length > 0 ? (
          <React.Fragment>
            <h3>
              Image Use
              {uses.length !== 1 ? 's' : ''}
            </h3>
            {uses.map((iu, index) => (
              <React.Fragment key={iu.Id}>
                <div css={flex}>
                  <div css={leftCol}>
                    <ChooseBestImageSource
                      candidates={[iu.Image__r.LongURL__c, iu.Image__r.OriginURL__c, iu.Image__r.CapturedCopy__c]}
                      type="image"
                    >
                      {({ url }) => <SexyThumbnail url={url} />}
                    </ChooseBestImageSource>
                  </div>
                  <div css={rightCol}>
                    <SexyDefinitionList
                      items={[
                        ['Copyright Owner', caze.salesforce.RightsHolder_Name_FirstLast__c],
                        [
                          'Copyright Owner Location',
                          [
                            // caze.salesforce.Photographer__r.BillingCountry === 'United States' &&
                            // caze.salesforce.Photographer__r.BillingState
                            //   ? caze.salesforce.Photographer__r.BillingState
                            //   : caze.salesforce.Photographer__r.BillingCity,
                            // caze.salesforce.Photographer__r.BillingCountry,
                          ].join(', '),
                        ],
                        ['Title of Image', iu.Image__r.Name],
                        ['Date Created', iu.Image__r.TakenD__c && moment.utc(iu.Image__r.TakenD__c).format('LL')],
                        ['Published Location', iu.Image__r.PublishedURL__c],
                      ]}
                    />
                    <SexySeparator space={20} />
                    <h3 css={danger}>Unauthorized Use</h3>
                    <SexyDefinitionList
                      items={[
                        ['Capture Page URL', iu.OriginLongURL__c],
                        ['Capture Image URL', iu.LongURL__c],
                      ]}
                    />
                  </div>
                </div>
                {index !== uses.length - 1 && <SexySeparator space={30} />}
              </React.Fragment>
            ))}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h3>Image Use</h3>
            <div css={flex}>
              <div css={leftCol}>
                <ChooseBestImageSource
                  candidates={[
                    caze.salesforce.CroppedPhoto__c,
                    caze.cluster && caze.cluster.images && caze.cluster.images[0] && caze.cluster.images[0].url,
                    caze.cluster && caze.cluster.matches && caze.cluster.matches[0] && caze.cluster.matches[0].url,
                  ]}
                  type="image"
                >
                  {({ url }) => <SexyThumbnail url={url} />}
                </ChooseBestImageSource>
              </div>
              <div css={rightCol}>
                <SexyDefinitionList
                  items={[
                    ['Copyright Owner', caze.salesforce.RightsHolder_Name_FirstLast__c],
                    [
                      'Copyright Owner Location',
                      [
                        caze.salesforce.Photographer__r.BillingCountry === 'United States' &&
                        caze.salesforce.Photographer__r.BillingState
                          ? caze.salesforce.Photographer__r.BillingState
                          : caze.salesforce.Photographer__r.BillingCity,
                        caze.salesforce.Photographer__r.BillingCountry,
                      ].join(', '),
                    ],
                    ['Title of Image', caze.salesforce.Photo_Title__c],
                    [
                      'Date Created',
                      caze.salesforce.Date_taken__c && moment(caze.salesforce.Date_taken__c).format('LL'),
                    ],
                    ['Published Location', caze.salesforce.FirstPublishedPlace__c],
                  ]}
                />
                <SexySeparator space={20} />
                <h3 css={danger}>Unauthorized Use</h3>
                <SexyDefinitionList
                  items={[
                    ['Capture Page URL', caze.salesforce.Infringement_Website__c],
                    ['Capture Image URL', caze.salesforce.Infringement_URL__c],
                    ['Additional URL(s)', caze.salesforce.AdditionalLinks__c],
                  ]}
                />
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </ContentSection>
  )
}
