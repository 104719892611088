import * as React from 'react'
import { css } from 'react-emotion'
import { Link } from 'react-router-dom'
import { PixsyPaper, PixsyGrid, PixsyGridItem } from '../../../Components'
import {
  Button,
  SexySeparator,
  AvatarIcon,
  MatchesIcon,
  CasesIcon,
  TakedownIcon,
  MultiImagesIcon,
  MatchesPlusIcon,
} from 'common'

const whatIsNextItems = [
  {
    Icon: CasesIcon,
    bodyText: `Your case dashboard provides you with status updates. You can check the progress of your case here.`,
    buttonText: 'Monitor your cases',
    linkPath: '/cases/all',
  },
  {
    Icon: AvatarIcon,
    bodyText: `A full and complete bio improves the value of your case and the likelihood of a successful outcome.`,
    buttonText: 'Update Bio',
    linkPath: '/profile/bio',
  },
  {
    Icon: MatchesIcon,
    bodyText: `Don't stop now, review more matches and take action against other unauthorized uses.`,
    buttonText: 'Review Matches',
    linkPath: '/matches',
  },
]

const moreFromPixsyItems = [
  {
    Icon: MultiImagesIcon,
    text: 'Import more images',
  },
  {
    Icon: TakedownIcon,
    text: 'Send takedown notices',
  },
  {
    Icon: MatchesPlusIcon,
    text: 'Priority match scanning',
  },
]

export class SuccessStage extends React.PureComponent {
  Heading = () => (
    <PixsyGrid direction="column" spacing={2} style={headingStyle}>
      <h1>Congratulations!</h1>
      <h3>Your case submission is now complete (Ref: {this.props.case.reference})</h3>
      <br />
      <p>
        Your case is on its way to a dedicated Case Manager for review. Our review times vary depending on demand, but
        you should expect to hear the outcome of the review within 2-3 weeks. You can reply to the case email you
        received at any time with questions, or find answers at{' '}
        <a href="https://support.pixsy.com" target="_blank">
          support.pixsy.com
        </a>
        .
      </p>
    </PixsyGrid>
  )

  WhatIsNextItem = ({ Icon, bodyText, buttonText, linkPath }) => (
    <PixsyGridItem xs={12} sm={12} md={6} xl={4} lg={4} css={whatisNextItemStyle}>
      <PixsyGrid direction="row" spacing={2} justify="space-between">
        <PixsyGridItem xs={12}>
          <Icon />
        </PixsyGridItem>
        <PixsyGridItem xs={12}>
          <p>{bodyText}</p>
        </PixsyGridItem>
        <PixsyGridItem xs={12} flex justify="center" alignItems="flex-end">
          <Link to={linkPath}>
            <Button mini>{buttonText}</Button>
          </Link>
        </PixsyGridItem>
      </PixsyGrid>
    </PixsyGridItem>
  )

  WhatIsNext = () => {
    const { WhatIsNextItem } = this
    return (
      <PixsyGrid direction="column" spacing={4} style={whatisNextStyle}>
        <h3>What's next?</h3>
        <PixsyGridItem xs={12}>
          <PixsyGrid spacing={5}>
            {whatIsNextItems.map((item, i) => (
              <WhatIsNextItem key={i} {...item} />
            ))}
          </PixsyGrid>
        </PixsyGridItem>
      </PixsyGrid>
    )
  }

  MoreFromPixsyItem = ({ Icon, text }) => (
    <PixsyGridItem xs={12} md={4}>
      <Icon />
      <p>{text}</p>
    </PixsyGridItem>
  )

  MoreFromPixsy = () => {
    const { MoreFromPixsyItem } = this
    return (
      <PixsyGrid direction="column" spacing={1} style={moreFromPixsyStyle}>
        <h3>Want more from Pixsy?</h3>
        <PixsyGridItem xs={12}>
          <PixsyGrid spacing={5} style={moreFromPixsyIconStyle}>
            {moreFromPixsyItems.map((item, i) => (
              <MoreFromPixsyItem key={i} {...item} />
            ))}
          </PixsyGrid>
        </PixsyGridItem>
        <Link to="/profile/plans">
          <Button medium>Upgrade your plan today</Button>
        </Link>
      </PixsyGrid>
    )
  }

  render() {
    const { Heading, WhatIsNext, MoreFromPixsy, SocialMediaBanner } = this
    return (
      <React.Fragment>
        <PixsyPaper padding="48px 70px 78px 70px">
          <Heading />
          <br />
          <SexySeparator />
          <WhatIsNext />
        </PixsyPaper>
        <PixsyPaper padding="42px 70px 45px 70px" expanded>
          <MoreFromPixsy />
        </PixsyPaper>
      </React.Fragment>
    )
  }
}

const headingStyle = css`
  text-align: center;
  h1 {
    font-size: 2.4em;
    font-weight: 500;
    margin: 0;
  }
  h3 {
    font-size: 1.8em;
    font-weight: 300;
    margin: 15px 0 15px 0;
  }
  a {
    text-decoration: underline;
  }
`

const whatisNextStyle = css`
  text-align: center;
  h3 {
    margin: 50px 0 30px 0;
    font-size: 1.8em;
    font-weight: 500;
  }
`

const whatisNextItemStyle = css`
  height: auto;
  min-height: 100% !important;
  text-align: center;
  > div {
    height: 100%;
    min-height: 220px;
  }
  /* align-items: center; */
  svg {
    height: 50px;
  }
`

const moreFromPixsyStyle = css`
  text-align: center;
  h3 {
    font-size: 1.8em;
    font-weight: 500;
    margin: 0 0 20px 0;
  }
`

const moreFromPixsyIconStyle = css`
  text-align: center;
  align-items: center;
  svg {
    height: 50px;
  }
`

const socialMediaBannerStyle = css`
  text-align: center;
  h3 {
    margin-top: 0;
    font-size: 1.8em;
    font-weight: 500;
  }
`