import React from 'react'
import { ContentSection, SexySeparator } from 'common'

export default function AttachmentsSection({ attachments }) {
  return (
    <ContentSection>
      <h1>Attachments</h1>
      <SexySeparator space={20} />
      <ul>
        {attachments.map(a => (
          <li key={a.id}>{a.name}</li>
        ))}
      </ul>
    </ContentSection>
  )
}
