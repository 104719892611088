import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { css } from 'react-emotion'
import { Elements, ElementsConsumer, } from '@stripe/react-stripe-js'
import { notifSend } from 'redux-notifications/lib/actions'
import { AlignLabels, Button } from 'common'
import { Glyphicon, FormGroup, InputGroup } from 'react-bootstrap'
import { handleStripePayment } from '../../../../../redux/modules/registrations'

class LastCCForm extends Component {
  constructor(props) {
    super(props)
  }

  handleSubmit = async (values) => {
    const { registration, dispatch, notifSend, stripe, elements } = this.props

    if (!stripe || !elements) {
      return notifSend({
        message:
          'Our payment partner Stripe is unable to verify the authenticity of your payment. Please disable any content blocking features and/or AdBlockers on this page, reload, and try again.',
        kind: 'warning',
        dismissAfter: 15e3,
      })
    }

    const coupon =
      (typeof registration.couponUsed === 'string' && registration.couponUsed.length > 0 && registration.couponUsed) ||
      undefined
    return dispatch(
      handleStripePayment(registration._id, {
        registrationId: registration.registration__c,
        coupon,
      })
    )
  }

  render() {
    const { handleSubmit, submitting, user } = this.props



    return (
      <form css={wrap} onSubmit={handleSubmit(this.handleSubmit)}>
        <AlignLabels align="right" width="190px">
          <FormGroup>
            <InputGroup>
              <InputGroup.Addon>
                <Glyphicon glyph="credit-card" />
              </InputGroup.Addon>
              <input
                css={ccInput}
                type="text"
                placeholder="Card Number"
                value={`**** **** **** ${user.stripe.last4}`}
                disabled
              />
            </InputGroup>
          </FormGroup>

          <div css={buttonAndStripe}>
            <Button submit disabled={submitting}>
              Submit Secure Payment
            </Button>
            <a href="https://stripe.com" target="_blank">
              <div css={stripeWrap}>
                <img src="/img/powered_by_stripe.svg" />
              </div>
            </a>
          </div>
        </AlignLabels>
      </form>
    )
  }
}

const InjectedLastCCForm = (props) => (
  <ElementsConsumer>
    {({ stripe, elements }) => <LastCCForm stripe={stripe} elements={elements} {...props} />}
  </ElementsConsumer>
)

const ConnectedStripeLastCCForm = reduxForm({
  form: 'registrationPaymentForm',
  onSubmit: (values, dispatch, props) =>
    dispatch(values._id, handleStripePayment(values)).then(() => props.onSuccess && props.onSuccess()),
})(connect(null, { notifSend })(InjectedLastCCForm))

export default function StripeLastCCForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <Elements stripe={stripe} elements={elements}>
          <ConnectedStripeLastCCForm {...props} />
        </Elements>
      )}
    </ElementsConsumer>
  )
}

const ccInput = css`
  width: 100%;
  text-align: center;
  letter-spacing: 12px;
`

const wrap = css`
  position: relative;

  & > div[class*='-Wrap'],
  & > div[class*='-half'] {
    margin-top: 25px;
  }
`

const buttonAndStripe = css`
  display: flex;
  justify-content: space-between;
  margin: 30px 0 20px;
`

const stripeWrap = css`
  margin-top: 10px;
`

// const lockedValue = css`
//   font-size: 1.14em;
//   font-weight: 500;
//   color: #000;
//   margin-bottom: 0;
// `
