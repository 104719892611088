import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { CardElement } from '@stripe/react-stripe-js'
import FormRow from './FormRow'
import Label from './Label'

export default function StripeCardInput(props) {
  const { input, meta = {}, label } = props
  const styles = {
    style: {
      base: {
        fontSize: '16px',
        color: '#3B3B41',
        letterSpacing: '1px',
        fontFamily: 'Helvetica',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  }

  return (
    <FormRow meta={meta}>
      <Label title={label} align={props.labelAlign} />
      <div css={actionArea}>
        <CardElement
          hidePostalCode
          onBlur={input.onBlur}
          onChange={input.onChange}
          onFocus={input.onFocus}
          options={{ ...styles }}
        />
      </div>
    </FormRow>
  )
}

StripeCardInput.propTypes = {
  labelAlign: PropTypes.string,
}

StripeCardInput.defaultProps = {
  labelAlign: 'right',
}

const actionArea = css`
  flex: 1;
  padding: 12px 12px;
  border: 1px solid #c7c7c7;
`
