import React from 'react'
import styled from 'react-emotion'

/*
type RadioPropsType = {
  label: string,
  value: string,
}

type RadioGroupPropsType = {
  name: string,
  question: ?string,
  options: ?Array<RadioPropsType>,
  value: ?string,
  onChange: Function,
}

*/

const RadioGroupContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  line-height: 25px;
  margin-top: 5px;
  margin-bottom: 30px;
  span {
    padding: 0 25px 0 5px;
    font-weight: normal;
  }
`

export default function RadioGroup({
  name,
  question,
  options,
  value,
  onChange: handleChange,
}) {
  const renderedOptions = options || [
    {
      label: 'Yes',
      value: 'true',
    },
    {
      label: 'No',
      value: 'false',
    },
  ]

  return (
    <div>
      {question && <strong>{question}</strong>}
      <RadioGroupContainer onChange={handleChange}>
        {renderedOptions.map(option => (
          <div key={option.value}>
            <label>
              <input
                type="radio"
                name={name}
                value={option.value}
                checked={option.value === value}
              />

              <span>{option.label}</span>
            </label>
          </div>
        ))}
      </RadioGroupContainer>
    </div>
  )
}
