import React from 'react'
import { cx, css } from 'react-emotion'

const wrap = css`
  margin: 0 auto;
  width: 300px;
  text-align: center;
  opacity: 0;
  margin-bottom: 60px;
  ul {
    display: inline-block;
    padding-left: 0;
    margin-left: -6px;
  }
  p {
    font-size: 0.9em;
    color: #93939380;
  }
`

const visible = css`
  opacity: 1;
`

export default function CardListFooter({ total, pages, renderPagination }) {
  return (
    <div className={cx(wrap, Number.isInteger(total) && visible)}>
      {renderPagination()}
      <p>
        {`${Number(total).toLocaleString()} on ${Number(
          pages
        ).toLocaleString()} ${pages === 1 ? 'page' : 'pages'}`}
      </p>
    </div>
  )
}
