import { useApolloClient } from '@apollo/client'
import React, { Component, Fragment, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactModal from 'react-modal'
import Helmet from 'react-helmet'
import { push } from 'react-router-redux'
import { css, cx, injectGlobal } from 'react-emotion'
import { withRouter } from 'react-router'
import config from '../../config' // eslint-disable-line import/default
import { logout } from '../../redux/modules/auth'
import Navigation from './Navigation/Navigation'
import NavigationMV3 from './NavigationMV3/Navigation'
import Notifications from './Notifications/Notifications'
import { Wootric, Elevio, Mixpanel } from './Integrations'
import App from './App'
import Logo from './Logo'
// import Prompt from './Prompt/Prompt'

import Typekit from './Integrations/Typekit'
import GoogleAnalytics from './Integrations/GoogleAnalytics'
import NewRelic from './Integrations/NewRelic'
import Hubspot from './Integrations/Hubspot'
import MobileWarningScreen from './Warnings/MobileWarningScreen'
import VerifyEmailWarning from './Warnings/VerifyEmailWarning'

function getFaviconLink() {
  const linkData = [
    {
      rel: 'apple-touch-icon',
      href: '/pwa/ios-appicon-76-76.png',
      sizes: '76x76',
    },
    {
      rel: 'apple-touch-icon',
      href: '/pwa/ios-appicon-120x120.png',
      sizes: '120x120',
    },
    {
      rel: 'apple-touch-icon',
      href: '/pwa/ios-appicon-152x152.png',
      sizes: '152x152',
    },
    {
      rel: 'apple-touch-icon',
      href: '/pwa/ios-appicon-180x180.png',
      sizes: '180x180',
    },
    {
      rel: 'apple-touch-icon',
      href: '/pwa/ios-appicon-1024x1024.png',
      sizes: '1024x180',
    },
    {
      rel: 'icon',
      href: '/favicons/favicon-16x16.png',
      sizes: '16x16',
      type: 'image/png',
    },
    {
      rel: 'icon',
      href: '/favicons/favicon-32x32.png',
      sizes: '32x32',
      type: 'image/png',
    },
    {
      rel: 'mask-icon',
      href: '/favicons/safari-pinned-tab.svg',
      color: '#008aab',
    },

    {
      /* iPhone Xs Max (1242px x 2688px) */
      rel: 'apple-touch-startup-image',
      media: '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)',
      href: '/pwa/ios-launchimage-1242x2688.png',
    },
    {
      /* iPhone Xr (828px x 1792px) */
      rel: 'apple-touch-startup-image',
      media: '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)',
      href: '/pwa/ios-launchimage-828x1792.png',
    },
    {
      /* iPhone X, Xs (1125px x 2436px) */
      rel: 'apple-touch-startup-image',
      media: '(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)',
      href: '/pwa/ios-launchimage-1125x2436.png',
    },
    {
      /* iPhone 8 Plus, 7 Plus, 6s Plus, 6 Plus (1242px x 2208px) */
      rel: 'apple-touch-startup-image',
      media: '(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3)',
      href: '/pwa/ios-launchimage-1242x2208.png',
    },
    {
      /* iPhone 8, 7, 6s, 6 (750px x 1334px) */
      rel: 'apple-touch-startup-image',
      media: '(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)',
      href: '/pwa/ios-launchimage-750x1334.png',
    },
    {
      /* iPad Pro 12.9" (2048px x 2732px) */
      rel: 'apple-touch-startup-image',
      media: '(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)',
      href: '/pwa/ios-launchimage-2048x2732.png',
    },
    {
      /* iPad Pro 11” (1668px x 2388px) */
      rel: 'apple-touch-startup-image',
      media: '(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)',
      href: '/pwa/ios-launchimage-1668x2388.png',
    },
    {
      /* iPad Pro 10.5" (1668px x 2224px) */
      rel: 'apple-touch-startup-image',
      media: '(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)',
      href: '/pwa/ios-launchimage-1668x2224.png',
    },
    {
      /* iPad Mini, Air (1536px x 2048px) */
      rel: 'apple-touch-startup-image',
      media: '(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)',
      href: '/pwa/ios-launchimage-1536x2048.png',
    },
  ]

  return [...linkData, { rel: 'manifest', href: '/manifest.json' }]
}

function getFaviconMeta() {
  return [
    { name: 'apple-mobile-web-app-title', content: 'Pixsy' },
    { name: 'apple-mobile-web-app-capable', content: 'yes' },
    { name: 'application-name', content: 'Pixsy' },
    {
      name: 'msapplication-TileImage',
      content: require('../../../static/favicons/mstile-150x150.png'),
    },
    { name: 'msapplication-TileColor', content: '#008aab' },
    { name: 'theme-color', content: '#008aab' },
  ]
}

function AppContainer({ user, push, location, history, match, logout }) {
  const apolloClient = useApolloClient()

  useEffect(() => {
    ReactModal.setAppElement('#react-root')
  }, [])

  const handleLogout = useCallback(async () => {
    await logout()
    return apolloClient.clearStore()
  }, [])

  const darkMode = location.pathname.includes('/view')

  return (
    <div className={cx(appContainerStyles, darkMode ? 'app--dark' : 'app--light')}>
      <Helmet {...config.app.head} link={getFaviconLink()} meta={[...config.app.head.meta, ...getFaviconMeta()]} />

      <Logo />

      {user &&
        (user.features.matches3Available ? (
          <NavigationMV3 user={user} onLogout={handleLogout} push={push} />
        ) : (
          <Navigation user={user} onLogout={handleLogout} push={push} />
        ))}

      <App user={user} location={location} match={match} />

      <Notifications darkMode={darkMode} />

      <VerifyEmailWarning user={user} />

      <Elevio user={user} />

      {!__DEVELOPMENT__ && user && (
        <Fragment>
          <Wootric user={user} />
        </Fragment>
      )}

      {!global.E2E && <Hubspot user={user} location={location} />}
      <GoogleAnalytics user={user} history={history} />

      <Mixpanel user={user} />
      <NewRelic />
      <Typekit />
      {/* {<Prompt title="Pixsy"/>} */}
      <MobileWarningScreen />
    </div>
  )
}

export default withRouter(connect((state) => ({ user: state.auth.user }), { logout, push })(AppContainer))

AppContainer.propTypes = {
  user: PropTypes.object,
  logout: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

injectGlobal`
  html, body, #react-root, #react-root {
    height: 100vh;
  }
`
const appContainerStyles = css`
  height: 100%;
`
