import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { css, cx } from 'react-emotion'
import moment from 'moment'
import { notifSend } from 'redux-notifications/lib/actions'
import { connect } from 'react-redux'
import { ContentSection, SexySeparator, PriceTag, PaypalContext, StripeContext } from 'common'
import PaymentsContainer from './Payments/PaymentsContainer'

export class PaymentSection extends PureComponent {
  render() {
    const { case: caze, onPaymentSuccess, paid, instalment } = this.props

    const inLegal = caze.salesforce.Track__c === 'Legal'

    const PaymentSectionFooter = () => {
      if (paid) {
        return (
          <React.Fragment>
            <h5>Payment Received</h5>
            {Boolean(caze.salesforce.PaymentReceivedD__c)
              ? moment(caze.salesforce.PaymentReceivedD__c).format('LL')
              : moment().format('LL')}
          </React.Fragment>
        )
      }

      if (instalment) {
        return (
          <React.Fragment>
            <h5>Payment status</h5>
            Your part payments are being processed.
          </React.Fragment>
        )
      }

      return (
        <React.Fragment>
          <PaymentsContainer case={caze} onPaymentSuccess={onPaymentSuccess} />
        </React.Fragment>
      )
    }

    return (
      <PaypalContext>
        <StripeContext account="charges_payment">
          <ContentSection>
            <h1>Payment</h1>
            <SexySeparator space={20} />
            <div className={`${wrap} ${flex}`}>
              {inLegal ? (
                <div css={inLegalMessage}>This matter is now with our legal partners and payment is unavailable.</div>
              ) : (
                <React.Fragment>
                  <div css={cx(leftCol, (paid || instalment) && paidStyle)}>
                    <h5>Amount Due</h5>
                    <PriceTag
                      value={caze.salesforce.FinalPrice__c || caze.salesforce.CurrentPrice__c}
                      currency={caze.salesforce.CurrencyIsoCode}
                    />
                  </div>

                  <div>
                    <PaymentSectionFooter />
                  </div>
                </React.Fragment>
              )}
            </div>
          </ContentSection>
        </StripeContext>
      </PaypalContext>
    )
  }
}

export default connect(null, { notifSend })(PaymentSection)

PaymentSection.propTypes = {
  case: PropTypes.object.isRequired,
}

const wrap = css`
  h5 {
    color: #43434380;
    font-size: 0.9em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.15em;
  }
`

const flex = css`
  display: flex;
`

const leftCol = css`
  width: 280px;
  margin-right: 40px;
`

const paidStyle = css`
  opacity: 0.2;
`
const inLegalMessage = css`
  width: 100%;
  text-align: center;
  margin-top: 10px;
`
