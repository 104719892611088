import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'react-emotion'
import { CardElement } from '@stripe/react-stripe-js'

export default function StripeCardInputInline(props) {
  const { input } = props
  const styles = {
    style: {
      base: {
        fontSize: '16px',
        color: '#3B3B41',
        letterSpacing: '1px',
        fontFamily: 'Helvetica',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  }

  return (
    <div css={actionArea}>
      <CardElement
        hidePostalCode
        onBlur={input.onBlur}
        onChange={input.onChange}
        onFocus={input.onFocus}
        options={{ ...styles }}
      />
    </div>
  )
}

StripeCardInputInline.propTypes = {
  labelAlign: PropTypes.string,
}

StripeCardInputInline.defaultProps = {
  labelAlign: 'right',
}

const actionArea = css`
  flex: 1;
  padding: 12px 12px;
  border: 1px solid #c7c7c7;
`
