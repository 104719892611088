import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { asyncConnect } from 'redux-connect'
import styled, { css } from 'react-emotion'
import { Button, Dialog, LoadingOverlay } from 'common'
import { DATA_ELEVIO } from 'pixsy-constants'
import moment from 'moment'
import { notifSend } from 'redux-notifications/lib/actions'
import { load, remove } from '../../../redux/modules/rules'
import BulkPasteRulesModal from './BulkPasteRulesModal'

// const debug = require('debug')('profile:settings')

function mapStateToProps(state, ownProps) {
  const {
    rules: { loading },
    entities: { rules },
    pagination: { rulesByTab },
  } = state

  // const query = resolveLocation(match, location)
  // debug('mapStateToProps', query)

  // debug('key %o', paginationKey(query))
  const rulesPagination = rulesByTab['all'] || { ids: [] }
  const visibleRules = rulesPagination
    .ids
    .map((id) => rules[id])
    .filter(Boolean)

  return {
    rules: visibleRules,
    loading,
  }
}

class RuleSettings extends Component {
  state = { tab: 'ignored', modalOpen: false }

  formatRegExp(regexp) {
    return regexp
      .split('//')[1]
      .replace(/\\\./g, '.')
      .replace('|^https?:', '')
  }

  removeRule(rule) {
    const { notifSend, load, remove } = this.props
    notifSend({
      kind: 'info',
      message: `Unignoring matches on ${this.formatRegExp(rule.regexp)}.`,
      dismissAfter: 10e3,
    })

    remove(rule._id).then(load)
  }

  render() {
    const tabs = [
      { key: 'ignored', title: 'Ignored Websites' },
      // { key: 'whitelisted', title: 'Whitelisted Domains' },
      { key: 'approved', title: 'Approved Websites' },
    ]

    // TODO
    // const { rules } = this.props
    const { modalOpen, tab } = this.state
    const { rules, loading } = this.props
    const entries = rules.filter((r) => r.setting === tab)
    return (
      <div>
        <ButtonRow data-elevio-selector={DATA_ELEVIO.PROFILE_SETTINGS_DOMAINS}>
          {tabs.map(({ key, title }) => (
            <div
              className={`${TabButton} ${tab === key && TabButtonActive} `}
              key={key}
              onClick={() => this.setState({ tab: key })}
            >
              {title}
            </div>
          ))}
        </ButtonRow>

        <div css={scroll}>
          <LoadingOverlay show={loading}>
            {entries.length ? (
              <div css={tableDiv}>
                {entries.map((r) => (
                  <div className="entry" key={r._id}>
                    <div>
                      <div className="inner">
                        <span>{this.formatRegExp(r.regexp)}</span>
                      </div>
                    </div>
                    <div>
                      <div className="inner">
                        {r.legacyWhitelistEntry
                          ? 'a long time ago'
                          : moment(r.created).fromNow()}
                      </div>
                    </div>
                    <div>
                      &nbsp;
                      {/*Edit*/}
                    </div>
                    <div onClick={this.removeRule.bind(this, r)}>
                      <div className="inner">Delete</div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <Placeholder>You have not {tab} any websites yet. </Placeholder>
            )}
          </LoadingOverlay>
        </div>
        <div css={ignoredDomainStyle}>
          {entries.length > 999
            ? (
              <div style={{ fontSize: 14, color: '#808080', fontStyle: 'italic' }}>
                <span>more hidden - contact support</span>
              </div>
            )
            : (
              <div style={{ fontSize: 14, color: '#808080', fontStyle: 'italic' }}>
                <span>{entries.length} displayed</span>
              </div>
            )
          }
          <div css={batchImportSection}>
            <Button onClick={() => this.setState({ modalOpen: true })}>
              Import {tab} Websites
            </Button>
          </div>
        </div>
        <Dialog
          isOpen={modalOpen}
          onRequestClose={() => this.setState({ modalOpen: false })}
          wide
        >
          <BulkPasteRulesModal
            onSuccess={this.props.load}
            setting={tab}
            onClose={() => this.setState({ modalOpen: false })}
          />
        </Dialog>
      </div>
    )
  }
}

RuleSettings.propTypes = {
  rules: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default asyncConnect(
  [
    {
      promise: async function fetchDataForRuleSettings({
                                                         store: { getState, dispatch },
                                                       }) {
        const promise = dispatch(load())
        return __SERVER__ && promise
      },
    },
  ],
  mapStateToProps,
  {
    remove,
    load,
    notifSend,
  },
)(RuleSettings)

const ignoredDomainStyle = css`
  display: flex;
  justify-content: space-between;
`

const ButtonRow = styled.div`
  display: flex;
`

const TabButton = css`
  width: 33%;
  height: 60px;
  color: #43434380;
  border-bottom: 1px solid #f0f0f0;
  line-height: 1.35em;
  padding-bottom: 20px;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  text-align: center;

  display: flex;
  justify-content: center;

  font-size: 1.7em;
  font-weight: 400;

  flex: 1;

  &:hover {
    color: #434343;
    border-bottom: 3px solid #008aab;
  }
`
const batchImportSection = css`
  padding: 20px 30px 20px 30px;
  display: flex;
  justify-content: flex-end;
`
const TabButtonActive = css`
  color: #434343;
  border-bottom: 3px solid #008aab;
`

const scroll = css`
  margin-top: 20px;
  margin-bottom: 20px;
  height: 404px;
  overflow-y: scroll;
  overflow-x: hidden;

  @media (max-width: 768px) {
    height: auto;
  }
`

const tableDiv = css`
  width: 100%;

  .entry {
    height: 26px;

    > div {
      height: 26px;
      display: inline-block;
      vertical-align: top;

      .inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      &:nth-child(1) {
        width: 50%;

        .inner {
          span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }

      &:nth-child(2) {
        width: 25%;
        font-style: italic;
        font-size: 0.85em;
        color: #434343bf;
      }

      &:nth-child(3),
      &:nth-child(4) {
        font-size: 0.8em;
        width: 12.5%;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      &:nth-child(3) {
        color: #939393;
      }

      &:nth-child(4) {
        color: #e66363;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 768px) {
    .entry {
      margin-bottom: 16px;
      position: relative;

      > div {
        &:nth-child(1) {
          width: 70%;
        }

        &:nth-child(2) {
          position: absolute;
          left: 0;
          top: 20px;
        }

        &:nth-child(4) {
          // width: 30%;
        }
      }
    }
  }
`

const Placeholder = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #43434380;
  font-size: 1.4em;
`
