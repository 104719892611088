import cn from 'classnames'
import React from 'react'
import { css } from 'react-emotion'

/*
type ButtonGroupPropsType = {
  vertical: ?boolean,
  grow: ?('first' | 'last'), // optionally allow first or last to fill remaining horizontal space
  children: any,
}
*/
export default function ButtonGroup({ children, vertical, grow }) {
  const className = cn(
    vertical ? buttonGroupVertical : buttonGroupHorizontal,
    !vertical && {
      [growStyle]: !!grow,
      ['growFirst']: grow === 'first',
      ['growLast']: grow === 'last',
    }
  )

  return <div className={className}>{children}</div>
}

const buttonGroupVertical = css`
  a {
    button {
      width: 100%;
      margin-bottom: 5px;
    }
    button:last-child {
      margin-bottom: 0;
    }
  }
`

const buttonGroupHorizontal = css`
  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }
`

const growStyle = css`
  display: flex;
  .growFirst > a:first-child,
  .growLast > a:last-child {
    flex-grow: 1;
    button {
      width: 100%;
    }
  }
`
