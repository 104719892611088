import React from 'react'
import cn from 'classnames'
import { css } from 'react-emotion'

/*
type ProgressBarPropsType = {
  milestones: Array,
  currentMilestone: ?number,
}
*/

export default function ProgressBar({ milestones, currentMilestone = 0 }) {
  const totalMilestones = milestones.length

  if (__DEVELOPMENT__) {
    if (totalMilestones < 2)
      throw new Error('ProgressBar: component requires at least two milestones')

    if (currentMilestone < 0)
      throw new Error(
        'ProgressBar: currentMilestone cannot be a negative number'
      )

    if (currentMilestone > totalMilestones)
      throw new Error(
        'ProgressBar: currentMilestone cannot be greater than total milestones'
      )
  }

  const width = `${currentMilestone / totalMilestones * 100}%`
  const innerWidthStyle = { width }

  return (
    <div className={container}>
      <div className={inner} style={innerWidthStyle} />
      <div className={labels}>
        {milestones.map((milestone, index) => (
          <div
            key={milestone}
            className={cn({
              [current]: currentMilestone === index + 1,
              [previous]: currentMilestone > index + 1,
            })}
          >
            {milestone}
          </div>
        ))}
      </div>
    </div>
  )
}

const container = css`
  height: 9px;
  background-color: hsl( 0, 0%, 96% );
  margin-bottom: 50px;
`

const inner = css`
  position: relative;
  background-color: #00758d;
  height: 100%;
  transition: width 0.6s ease-out;
`

const labels = css`
  display: flex;
  justify-content: space-between;
  text-align: right;
  div {
    flex: 1;
  }
`

const current = css`
  color: #008aab;
`

const previous = css`
  color: hsl( 0, 0%, 85% );
`
