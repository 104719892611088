import PropTypes from 'prop-types'

import {
  LIST_TYPE,
  SEEN_STATE,
  SIMILARITY_TYPE,
  MATCHES_TYPE,
  DETAIL_MODAL_VIEW_TYPE,
  SORT_OPTIONS,
} from '../store/constants'
import { SEARCH_TYPE } from '../components/SearchInput/constants'

const Match = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  origin: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
  }),
  ignored: PropTypes.bool,
  isOnline: PropTypes.bool,
  created: PropTypes.string.isRequired,
})

const Cluster = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  tags: PropTypes.array.isRequired,
  created: PropTypes.string.isRequired,
  matches: PropTypes.arrayOf(Match),
  images: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      created: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      metadata: PropTypes.bool,
      origin: PropTypes.shape({
        source: PropTypes.string,
        url: PropTypes.string,
      }),
    })
  ),
})

const LastIgnoredMatch = PropTypes.shape({
  clusterId: PropTypes.string,
  matchId: PropTypes.string,
  undoFn: PropTypes.func,
})

const MultiSelectRichListItem = PropTypes.shape({
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  iconComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.func]),
  count: PropTypes.number,
})

const ListTypes = Object.values(LIST_TYPE)
const SeenStates = Object.values(SEEN_STATE)
const SimilarityTypes = Object.values(SIMILARITY_TYPE)
const MatchesTypes = Object.values(MATCHES_TYPE)
const SearchTypes = Object.values(SEARCH_TYPE)
const ModalViewTypes = Object.values(DETAIL_MODAL_VIEW_TYPE)

const FiltersData = PropTypes.shape({
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      iso: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      isPixsyJurisdiction: PropTypes.bool.isRequired,
    })
  ),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  matchSummary: PropTypes.shape({
    total: PropTypes.number.isRequired,
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        country: PropTypes.shape({
          iso: PropTypes.string.isRequired,
        }),
        matches: PropTypes.number.isRequired,
      })
    ),
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
        matches: PropTypes.number.isRequired,
      })
    ),
  }),
})

const MappedFiltersData = PropTypes.shape({
  isLoading: PropTypes.bool,
  totalMatches: PropTypes.number.isRequired,
  countryItems: PropTypes.arrayOf(MultiSelectRichListItem),
  categoryItems: PropTypes.arrayOf(MultiSelectRichListItem),
  imageItems: PropTypes.arrayOf(MultiSelectRichListItem),
  actionItems: PropTypes.arrayOf(MultiSelectRichListItem),
})

const SelectedFiltersState = PropTypes.shape({
  selectedCountryIds: PropTypes.arrayOf(PropTypes.string),
  selectedCategoryIds: PropTypes.arrayOf(PropTypes.string),
  selectedImageIds: PropTypes.arrayOf(PropTypes.string),
  selectedActionIds: PropTypes.arrayOf(PropTypes.string),
  selectedSeenState: PropTypes.oneOf(SeenStates),
  selectedSimilarityType: PropTypes.oneOf(SimilarityTypes),
  selectedMatchesType: PropTypes.oneOf(MatchesTypes),
  selectedYearRange: PropTypes.arrayOf(PropTypes.number),
  cluster: Cluster,
  clusterId: PropTypes.string,
})

const Suggestion = PropTypes.shape({
  type: PropTypes.oneOf(SearchTypes),
  value: PropTypes.string,
})

const PageOptions = PropTypes.shape({
  perPage: PropTypes.number,
  forcePage: PropTypes.number,
  sort: PropTypes.oneOf(SORT_OPTIONS.map((option) => option.value)),
})

const PageOptionsProps = PropTypes.shape({
  perPage: PropTypes.number,
  forcePage: PropTypes.number,
  sort: PropTypes.oneOf(SORT_OPTIONS.map((option) => option.value)),
  pageCount: PropTypes.number,
  total: PropTypes.number,
  onSortChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onPerPageChange: PropTypes.func,
})

const FiltersProps = PropTypes.shape({
  country: PropTypes.arrayOf(PropTypes.string),
  category: PropTypes.arrayOf(PropTypes.string),
  images: PropTypes.arrayOf(PropTypes.string),
  topMatch: PropTypes.bool,
  seen: PropTypes.bool,
  fromDate: PropTypes.date,
  toDate: PropTypes.date,
  tags: PropTypes.arrayOf(PropTypes.string),
})

export {
  Match,
  Cluster,
  LastIgnoredMatch,
  MultiSelectRichListItem,
  ListTypes,
  MappedFiltersData,
  FiltersData,
  SeenStates,
  MatchesTypes,
  SelectedFiltersState,
  Suggestion,
  ModalViewTypes,
  PageOptions,
  PageOptionsProps,
  SimilarityTypes,
  FiltersProps,
}
