import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { css } from 'react-emotion'
import { Elements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js'
import { notifSend } from 'redux-notifications/lib/actions'
import {
  StripeCardInputInline,
  AlignLabels,
  TextFieldNoLabel,
  CountrySelectStandalone,
  Button,
  SexySeparator,
} from 'common'
import COUNTRIES from 'pixsy-constants/countries.json'
import { handleInstalmentStripePayment, handleCheckoutStripePayment } from '../../../redux/modules/cases'

class StripeForm extends Component {
  componentDidMount = async () => {
    const { instalment, notifSend, onPaymentSuccess } = this.props

    const query = new URLSearchParams(window.location.search)

    if (query.get('success')) {
      onPaymentSuccess({ instalment })
    }

    if (query.get('canceled')) {
      notifSend({
        message: 'Order canceled',
        kind: 'warning',
        dismissAfter: 15e3,
      })
    }
  }

  /**
   *
   * @param {Object} response to create address from
   * @returns {Object} address to return
   */
  getAddress(response) {
    return response.token && response.token.card
      ? {
          line1: response.token.card.address_line1,
          postalCode: response.token.card.address_line2,
          city: response.token.card.address_city,
          country: response.token.card.address_country,
          state: response.token.card.address_state,
        }
      : {}
  }

  handleSubmit = async (values) => {
    const {
      stripe,
      elements,
      caze,
      notifSend,
      handleInstalmentStripePayment,
      handleCheckoutStripePayment,
      instalment,
      parts,
      interval,
      startDate,
      onPaymentSuccess,
    } = this.props

    const country = COUNTRIES.find((country) => country.country === values.country)

    if (!stripe || !elements) {
      return notifSend({
        message:
          'Our payment partner Stripe is unable to verify the authenticity of your payment. Please disable any content blocking features and/or AdBlockers on this page, reload, and try again.',
        kind: 'warning',
        dismissAfter: 15e3,
      })
    }

    if (instalment) {
      const cardElement = elements.getElement(CardElement)
      const response = await stripe.createToken(cardElement, {
        name: values.name,
        address_line1: values.street,
        address_line2: values.postal_code,
        address_city: values.city,
        address_state: values.state,
        address_country: country ? country.iso : null,
      })

      if (response.error) {
        notifSend({
          message: response.error.message,
          kind: 'warning',
          dismissAfter: 10e3,
        })
      } else {
        const innerResponse = await handleInstalmentStripePayment({
          token: response.token.id,
          requestId: caze.salesforce.Id,
          email: values.email,
          address: this.getAddress(response),
          parts,
          interval,
          startDate: startDate.unix(),
        })

        if (!innerResponse.error) {
          onPaymentSuccess({ instalment })
        }
      }
    } else {
      const cleanedUrl = window.location.href.split('?')[0]
      const response = await handleCheckoutStripePayment({
        requestId: caze.salesforce.Id,
        url: cleanedUrl,
      })

      window.location.href = response.payload.sessionUrl
    }
  }

  render() {
    const { handleSubmit, submitting, instalment } = this.props

    return (
      <form css={wrap} onSubmit={handleSubmit(this.handleSubmit)}>
        <AlignLabels align="right" width="190px">
          {instalment ? (
            <div>
              <Field name="name" label="Name on Card" component={TextFieldNoLabel} props={{ required: true }} />
              <Field name="street" label="Street" component={TextFieldNoLabel} props={{ required: true }} />
              <div css={half}>
                <div>
                  <Field
                    name="postal_code"
                    label="Postal Code"
                    component={TextFieldNoLabel}
                    props={{ required: true }}
                  />
                </div>
                <div>
                  <Field name="city" label="City" component={TextFieldNoLabel} props={{ required: true }} />
                </div>
              </div>
              <div css={half}>
                <div>
                  <Field name="state" label="State" component={TextFieldNoLabel} props={{ required: true }} />
                </div>
                <div>
                  <Field
                    name="country"
                    label="Country"
                    component={CountrySelectStandalone}
                    props={{ required: true }}
                  />
                </div>
              </div>
              <SexySeparator space={20} />

              <Field type="text" label="Card Number" name="card" component={StripeCardInputInline} />

              <Field name="email" label="Email for Receipt" props={{ required: true }} component={TextFieldNoLabel} />
            </div>
          ) : (
            <div />
          )}

          <div css={buttonAndStripe}>
            {instalment ? (
              <Button submit disabled={submitting}>
                Secure Payment
              </Button>
            ) : (
              <Button submit disabled={submitting}>
                Checkout
              </Button>
            )}
            <a href="https://stripe.com" target="_blank">
              <div css={stripeWrap}>
                <img src="/img/powered_by_stripe.svg" />
              </div>
            </a>
          </div>
        </AlignLabels>
      </form>
    )
  }
}

const InjectedStripeForm = (props) => (
  <ElementsConsumer>
    {({ stripe, elements }) => <StripeForm stripe={stripe} elements={elements} {...props} />}
  </ElementsConsumer>
)

const ConnectedStripePaymentForm = reduxForm({
  form: 'infringerPaymentForm',
})(
  connect(null, {
    notifSend,
    handleInstalmentStripePayment,
    handleCheckoutStripePayment,
  })(InjectedStripeForm)
)

export default function StripePaymentForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <Elements stripe={stripe} elements={elements}>
          <ConnectedStripePaymentForm {...props} />
        </Elements>
      )}
    </ElementsConsumer>
  )
}

const wrap = css`
  position: relative;

  input,
  select {
    margin-top: 25px;
  }
`

const buttonAndStripe = css`
  display: flex;
  justify-content: space-between;
  margin: 30px 0 20px;
`

const stripeWrap = css`
  margin-top: 10px;
`

const half = css`
  display: flex;
  justify-content: space-between;

  > * {
    flex-basis: 48% !important;
  }

  flex: 1;
`
