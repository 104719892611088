import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import * as Sentry from '@sentry/browser'

const StripeContext = ({ account, children }) => {
  const [stripeObject, setStripeObject] = useState(null)
  const stripeKey = useStripeProvider(account)
  useEffect(() => {
    const fetchStripeObject = () => {
      if (stripeKey) {
        const res = loadStripe(stripeKey)
        setStripeObject(res)
      }
    }
    fetchStripeObject()
  }, [stripeKey])

  if (!stripeObject) return <p>Loading...</p>

  return (
    <React.Fragment>
      <Helmet>
        <script id="stripe-js" src="https://js.stripe.com/v3/" />
      </Helmet>
      {stripeKey && <Elements stripe={stripeObject}>{children}</Elements>}
    </React.Fragment>
  )}

StripeContext.propTypes = { children: PropTypes.node.isRequired }

export default StripeContext

/**
 * React Hook to initiate Stripe
 * @param {string} account Account
 */
function useStripeProvider(account) {
  const [stripe, setStripe] = useState(null)

  const loadStripeScript = useCallback((key) => {
    function _loadStripe(retries = 0) {
      const stripeJS = document.querySelector('#stripe-js')
      if (stripeJS) {
        stripeJS.addEventListener('load', () => {
          // Create Stripe instance once Stripe.js loads
          setStripe(key)
        })
      } else {
        if (retries === 5) {
          try {
            const script = document.createElement('script')
            script.id = 'stripe-js'
            script.src = 'https://js.stripe.com/v3/'
            document.body.appendChild(script)
            _loadStripe(++retries)
          } catch (err) {
            Sentry.captureException(err)
            console.error(err)
          }

          _loadStripe(++retries)
        } else if (retries === 20) {
          const err = new Error('Unable to load Stripe')
          Sentry.captureException(err)
          console.error(err)
        } else {
          setTimeout(() => {
            console.info('Reloading: ', retries)
            _loadStripe(++retries)
          }, 500)
        }
      }
    }

    _loadStripe()
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      // @ts-ignore
      const environmentObject = __SERVER__ ? process.env : window
      const key = environmentObject[`STRIPE_${account.toUpperCase()}_PUBLISHABLE_KEY`]

      if (window.Stripe) {
        setStripe(key)
      } else {
        loadStripeScript(key)
      }
    }, 1500)

    // componentWillUnmount
    return () => clearTimeout(timer)
  }, [account, loadStripeScript, stripe])

  return stripe
}
