import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { SexySeparator } from 'common'
import { css, cx } from 'react-emotion'
import moment from 'moment'
import { connect } from 'react-redux'
import COUNTRIES from 'pixsy-constants/countries.json'
import { HeroCard, CategoryCard, TopMatchesCard, CountryCard, IconCard } from '../Card'
import { requestEmailVerification } from '../../../../redux/modules/auth'
import getTagSuggestions from '../../../../getTagSuggestions'
import MatchViewer3Card from '../MatchViewer3Card'

class MatchesOverviewGrid extends Component {
  renderCategoryCard({ tag, count }) {
    const { matches3Enabled } = (this.props.user && this.props.user.features) || {}

    if (!tag) return null

    return (
      <Link to={matches3Enabled ? `/matches/v3/${tag}` : `/matches/${tag}`}>
        <CategoryCard n={count} tag={tag} />
      </Link>
    )
  }

  formatDuration(d) {
    if (!d) return '-'

    let humanized = moment(d).fromNow()

    if (humanized.includes('a few seconds ago')) {
      humanized = 'just now'
    } else if (humanized.includes(' ago')) {
      humanized = humanized.replace(' ago', '')
    }

    return humanized
  }

  isCountry({ tag }) {
    return tag.startsWith('country') && tag !== 'country:undefined' // prevents outline for 'undefined' country
  }

  isCategory({ tag }) {
    return tag.startsWith('category')
  }

  isPreviouslyActedUponTag({ tag }) {
    return ['hidden:true', 'flagged', 'submitted:case', 'submitted:takedown'].includes(tag)
  }

  renderIconCard(tag, extension = '') {
    if (!tag) return null
    const tags = this.props.tags || []

    const tagCount = tags.reduce(
      (obj, item) => ({
        ...obj,
        [item.tag]: item.count,
      }),
      {}
    )

    return (
      <Link to={`/matches/${tag}${extension}`}>
        <IconCard n={tagCount[tag]} tag={tag} />
      </Link>
    )
  }

  render() {
    const {
      tags,
      user,
      commercial,
      requestEmailVerification,
      actionable,
      // lastCreated,
    } = this.props

    const { matches3Available, matches3Enabled } = (user && user.features) || {}

    const tagCount = tags.reduce(
      (obj, item) => ({
        ...obj,
        [item.tag]: item.count,
      }),
      {}
    )

    const COMMON_COUNTRIES = getTagSuggestions(tags.filter(this.isCountry)).slice(0, 8)

    const PRIMARY = ['unseen', 'new']

    const RELEVANT = [{ tag: 'multi:image' }, { tag: 'multi:match' }, { tag: 'similarity:high' }]

    var COMMERCIAL = [
      {
        tag: 'commercial:true',
        count: commercial,
        extension: '?sort=-score',
      },
    ]

    const ACTED_UPON = getTagSuggestions(tags.filter(this.isPreviouslyActedUponTag)).slice(0, 4)

    const pixsyJurisdictionTags = COUNTRIES.filter((c) => c.resolution).map((c) => 'country:' + c.iso)

    const pixsyJurisdictionMatchCount = tags
      .filter(({ tag, count }) => pixsyJurisdictionTags.includes(tag))
      .reduce((acc, { count, tag }) => acc + count, 0)

    const CATEGORIES = getTagSuggestions(tags.filter(this.isCategory)).slice(0, 4)

    const common = getTagSuggestions(
      tags.filter(
        ({ tag }) =>
          !tag.startsWith('country') &&
          !tag.startsWith('category') &&
          !this.isPreviouslyActedUponTag({ tag }) &&
          !RELEVANT.includes(tag) &&
          !PRIMARY.includes(tag) &&
          !tag.startsWith('hidden') &&
          !tag.startsWith('invalid') &&
          !tag.startsWith('similarity')
      )
    ).slice(0, 4)

    const isVerified = user.verification && user.verification.email && user.verification.email.dateVerified

    const topMatchCount = (tagCount['top:us'] || 0) + (tagCount['top:world'] || 0)
    const flaggedCount = tagCount['flagged'] || 0
    const pixsyFlaggedCount = tagCount['pixsy:flagged'] || 0

    return (
      <React.Fragment>
        <div css={cardRow}>
          {pixsyFlaggedCount > 0 ? (
            <div css={quarterCard}>
              <Link to={matches3Enabled ? '/matches/v3/pixsy:flagged' : '/matches/pixsy:flagged'}>
                <HeroCard n={pixsyFlaggedCount} row1="Pixsy" row2="Flagged" />
              </Link>
            </div>
          ) : topMatchCount > 0 ? (
            <div css={cx(quarterCard, topMatchesCard)}>
              <Link to="/matches/top">
                <TopMatchesCard n={tagCount['unseen']} />
              </Link>
            </div>
          ) : flaggedCount > 0 ? (
            <div css={quarterCard}>
              <Link to={matches3Enabled ? '/matches/v3/flagged' : '/matches/flagged'}>
                <HeroCard n={flaggedCount} row1="Flagged" row2="matches" />
              </Link>
            </div>
          ) : (
            <div css={quarterCard}>
              <Link to={matches3Enabled ? '/matches/v3' : '/matches'}>
                <HeroCard n={actionable} row1="All" row2="matches" />
              </Link>
            </div>
          )}
          <div css={quarterCard}>
            <Link to={matches3Enabled ? '/matches/unseen?sort/v3=-created' : '/matches/unseen?sort=-created'}>
              <HeroCard n={tagCount['unseen']} row1="Unseen" row2="matches" />
            </Link>
          </div>
          <div css={quarterCard}>
            <Link to={matches3Enabled ? '/matches/v3/pixsy:jurisdictions' : '/matches/pixsy:jurisdictions'}>
              <HeroCard n={pixsyJurisdictionMatchCount} row1="Pixsy" row2="Jurisdictions" />
            </Link>
          </div>

          <div css={quarterCard} onClick={() => (isVerified ? () => {} : requestEmailVerification())}>
            {isVerified && matches3Available ? (
              <Link to={matches3Enabled ? '/matches/v3' : '/matches/activate-v3'}>
                <MatchViewer3Card />
              </Link>
            ) : isVerified ? (
              <Link to="/profile/plans">
                <HeroCard n={'Active'} row1="monitoring" row2="status" />
              </Link>
            ) : (
              <HeroCard n={1} warning="Send email now" row1="Account" row2="not verified" />
            )}
          </div>
          {/* <div css={quarterCard}>
            <Link to="/matches?sort=-created">
              <HeroCard
                n={this.formatDuration(lastCreated)}
                row1="last"
                row2="match"
              />
            </Link>
          </div> */}
        </div>

        <SexySeparator space={0} label="Relevant" />

        <div css={cardRow}>
          {RELEVANT.map(({ tag, extension }) => (
            <div key={tag} css={quarterCard}>
              {this.renderIconCard(tag, extension)}
            </div>
          ))}
          {COMMERCIAL.map(({ tag, count }) => (
            <div key={tag} css={quarterCard}>
              <Link to="/matches/commercial:true">
                <IconCard n={count} tag={tag} />
              </Link>
            </div>
          ))}
        </div>

        {COMMON_COUNTRIES.length > 0 && (
          <React.Fragment>
            <SexySeparator space={0} label="Matches By Country" />

            <div css={cardRow}>
              {COMMON_COUNTRIES.map(({ tag, count }) => (
                <div css={quarterCard} key={tag}>
                  <Link to={matches3Enabled ? `/matches/v3/${tag}` : `/matches/${tag}`}>
                    <CountryCard n={count} country={tag.split(':')[1]} />
                  </Link>
                </div>
              ))}
            </div>
          </React.Fragment>
        )}

        {common[0] && (
          <React.Fragment>
            <SexySeparator space={0} label="Common" />

            <div css={cardRow}>
              {Array(Math.min(4, common.length))
                .fill(0)
                .map((_, n) => (
                  <div key={n} css={quarterCard}>
                    {this.renderCategoryCard(common[n])}
                  </div>
                ))}
            </div>
          </React.Fragment>
        )}
        {CATEGORIES[0] && (
          <React.Fragment>
            <SexySeparator space={0} label="Matches By Category" />
            <div css={cardRow}>
              {Array(Math.min(4, CATEGORIES.length))
                .fill(0)
                .map((_, n) => (
                  <div key={n} css={quarterCard}>
                    {this.renderCategoryCard(CATEGORIES[n])}
                  </div>
                ))}
            </div>
          </React.Fragment>
        )}

        <SexySeparator space={0} label="Previously Acted Upon" />

        <div css={cardRow}>
          {Array(Math.min(4, ACTED_UPON.length))
            .fill(0)
            .map((_, n) => (
              <div key={n} css={quarterCard}>
                {this.renderCategoryCard(ACTED_UPON[n])}
              </div>
            ))}
        </div>
      </React.Fragment>
    )
  }
}

MatchesOverviewGrid.propTypes = {
  user: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
}

export default connect(null, { requestEmailVerification })(MatchesOverviewGrid)

const cardRow = css`
  display: block;
  width: 100%;
  clear: both;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    display: inline-block;
    padding: 0px 10px 20px 10px;
  }
`

const quarterCard = css`
  width: 24%;
  @media (max-width: 768px) {
    width: 48%;
    display: block;
    float: left;
    margin: 0 1%;
  }
`
const topMatchesCard = css`
  svg {
    margin-top: 8px;
  }

  label {
    margin-bottom: 14px;
  }
`

// const halfCard = css`
//   width: 48.75%;
//   @media (max-width: 768px) {
//     width: 99%;
//     display: inline-block;
//     float: left;
//     margin: 0 1%;
//   }
// `

// const halfCardRow = css`
//   width: 50%;
//   display: inline-flex;
//   justify-content: space-between;
//   flex-wrap: wrap;
//   padding: 0;
//   &:nth-of-type(2n) {
//     padding-left: 7px;
//   }
//   &:nth-of-type(1n) {
//     padding-right: 7px;
//   }
//   @media (max-width: 768px) {
//     display: inline-block;
//     width: 100%;
//     padding-right: 10px;
//     padding-left: 10px;
//     &:nth-of-type(2) {
//       padding-top: 0;
//     }
//   }
// `
